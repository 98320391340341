var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"services"},[_c('div',{staticClass:"container services__container"},[_c('h2',{staticClass:"title services__header"},[_vm._v("Наши услуги")]),_c('ul',{staticClass:"services__list"},[_c('li',{staticClass:"services__item",on:{"click":function($event){return _vm.turn()}}},[_vm._m(0),_vm._m(1)]),_c('li',{staticClass:"services__item",on:{"click":function($event){return _vm.turn()}}},[_vm._m(2),_vm._m(3)]),_c('li',{staticClass:"services__item",on:{"click":function($event){return _vm.turn()}}},[_vm._m(4),_vm._m(5)]),_c('li',{staticClass:"services__item",on:{"click":function($event){return _vm.turn()}}},[_vm._m(6),_vm._m(7)])])])]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-front services__item-front1"},[_c('h3',{staticClass:"services__item-header"},[_vm._v("Лазерная резка")]),_c('img',{staticClass:"services__img",attrs:{"src":"img/1.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-back"},[_c('h3',{staticClass:"services__item-header services__item-header-back"},[_vm._v("Лазерная резка")]),_c('ul',{staticClass:"services__back-list"},[_c('li',{staticClass:"services__back-item"},[_vm._v("Модель – G-Weike LF3015LN;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Мощность – 1500 Вт;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Рабочее поле – 1500х3000 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Толщина разрезаемого металла – до 14 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Газы используемые при резке – азот, кислород, воздух;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Точность позиционирования – ±0,03 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Повторяемость – "),_c('span',{staticStyle:{"font-family":"sans-serif"}},[_vm._v("±")]),_vm._v("0,02 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Предельные отклонения получаемых деталей – h12…h14, H12…H14.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-front services__item-front2"},[_c('h3',{staticClass:"services__item-header"},[_vm._v("Гибка листового металла")]),_c('img',{staticClass:"services__img",attrs:{"src":"img/2.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-back"},[_c('h3',{staticClass:"services__item-header services__item-header-back"},[_vm._v("Гибка листового металла")]),_c('ul',{staticClass:"services__back-list"},[_c('li',{staticClass:"services__back-item"},[_vm._v("Модели – Durma AD-R 30135 и AD-R 25100;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Максимальная длина гиба – 3100 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Максимальное усилие – 135 тонн;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Стоимость зависит от радиуса, полок, углов, толщины. Для ее уточнения напишите на почту или позвоните.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-front services__item-front3"},[_c('h3',{staticClass:"services__item-header"},[_vm._v("Координатно-пробивные работы")]),_c('img',{staticClass:"services__img",attrs:{"src":"img/3.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-back"},[_c('h3',{staticClass:"services__item-header services__item-header-back"},[_vm._v("Координатно-пробивные работы")]),_c('ul',{staticClass:"services__back-list"},[_c('li',{staticClass:"services__back-item"},[_vm._v("Модель – Amada Europe 2510;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Развиваемое усилие – 20 тонн;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Максимальные размеры обрабатываемого листа – 1250х5000 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Количество инструмента в барабане – до 27;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Толщина пробиваемого металла – до 3 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Точность позиционирования – "),_c('span',{staticStyle:{"font-family":"sans-serif"}},[_vm._v("±")]),_vm._v("0,1 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Повторяемость – "),_c('span',{staticStyle:{"font-family":"sans-serif"}},[_vm._v("±")]),_vm._v("0,07 мм.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-front services__item-front4"},[_c('h3',{staticClass:"services__item-header"},[_vm._v("Рубка металла гильотиной")]),_c('img',{staticClass:"services__img",attrs:{"src":"img/4.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__item-card services__item-back"},[_c('ul',{staticClass:"services__back-list"},[_c('h3',{staticClass:"services__item-header services__item-header-back"},[_vm._v("Рубка металла гильотиной")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Модель – HESSE DHGM 3006, Durma MS 1303, MS 2504;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Толщина разрезаемого металла – до 6 мм;")]),_c('li',{staticClass:"services__back-item"},[_vm._v("Длина разрезаемого металла – до 3100 мм.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"calculator"},[_c('div',{staticClass:"container calculator__container"},[_c('h2',{staticClass:"title calculator__header"},[_vm._v("Рассчитать стоимость лазерной резки")]),_c('iframe',{staticClass:"frame",attrs:{"id":"frame","src":"https://versus.absolute.msk.ru/form/","frameborder":"0"}})])])
}]

export { render, staticRenderFns }