import Vue from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'
import ServicesView from '../views/ServicesView.vue'
import ProductsView from '../views/ProductsView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import DesignerView from '../views/DesignerView.vue'
import OptimaView from '../views/OptimaView.vue'
import StandardView from '../views/StandardView.vue'
import MaxView from '../views/MaxView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import ElementsView from '../views/ElementsView.vue'
import RacksView from '../views/RacksView.vue'
import AccessoriesView from '../views/AccessoriesView.vue'
import ConstructionsView from '../views/ConstructionsView.vue'
import ProductView from '../views/ProductView.vue'
import DeployView from '../views/DeployView.vue'
import ProductAccessoriesView from '../views/ProductAccessoriesView.vue'
import ConstructionElementView from '../views/ConstructionElementView.vue'
import NewsView from '../views/NewsView.vue'
import NewView from '../views/NewView.vue'
import LoadCalculationView from '../views/LoadCalculationView.vue'
import ProtectiveCoveringView from '../views/ProtectiveCoveringView.vue'
import ClimaticView from '../views/ClimaticView.vue'
import ProjectView from '../views/ProjectView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },
  {
    path: '/projects/:name',
    name: 'project',
    component: ProjectView
  },
  {
    path: '/info',
    name: 'info',
    component: DesignerView
  },
  {
    path: '/info/news',
    name: 'news',
    component: NewsView
  },
  {
    path: '/info/news/:new',
    name: 'new',
    component: NewView
  },
  {
    path: '/info/climatic',
    name: 'climatic',
    component: ClimaticView
  },
  {
    path: '/info/track-load',
    name: 'track-load',
    component: LoadCalculationView
  },
  {
    path: '/info/protective-covering',
    name: 'protective-covering',
    component: ProtectiveCoveringView
  },
  {
    path: '/products/:seria/accessories',
    name: 'seria-accessories',
    component: ProductAccessoriesView
  },
  {
    path: '/products/optima',
    name: 'optima',
    component: OptimaView
  },
  {
    path: '/products/optima/:name',
    name: 'product-optima',
    component: ProductView
  },
  {
    path: '/products/standard',
    name: 'standard',
    component: StandardView
  },
  {
    path: '/products/standard/:name',
    name: 'product-standard',
    component: ProductView
  },
  {
    path: '/products/maks',
    name: 'maks',
    component: MaxView
  },
  {
    path: '/products/maks/:name',
    name: 'product-maks',
    component: ProductView
  },
  {
    path: '/products/elements',
    name: 'elements',
    component: ElementsView
  },
  {
    path: '/products/racks',
    name: 'racks',
    component: RacksView
  },
  {
    path: '/products/accessories',
    name: 'accessories',
    component: AccessoriesView
  },
  {
    path: '/products/constructions',
    name: 'constructions',
    component: ConstructionsView
  },
  {
    path: '/products/constructions/:name',
    name: 'constructions-element',
    component: ConstructionElementView
  },
  {
    path: '*',
    component: NotFoundView
  }
]

const router = new VueRouter({
  history: createWebHashHistory,
  base: process.env.BASE_URL,
  routes
})

export default router
