export default [
    {
        name: 'standard',
        seriaName: "Стандарт",
        text: 'Аксессуары серии «СТАНДАРТ» предназначены для монтажа поворотов, ответвлений и защиты кабельной трассы. В их число входят:',
        accessoriesList: [
            'угол горизонтальный 90 град. – предназначен для поворота трассы в горизонтальной плоскости',
            'угол горизонтальный 45 град. – предназначен для поворота трассы в горизонтальной плоскости',
            'угол вертикальный вверх 90 град. – предназначен для поворота трассы вверх',
            'угол вертикальный вверх 45 град. – предназначен для поворота трассы вверх',
            'угол вертикальный вниз 90 град. – предназначен для поворота трассы вниз',
            'угол вертикальный вниз 45 град. – предназначен для поворота трассы вниз',
            'ответвитель Т-образный – предназначен для организации Т-образного отвода',
            'ответвитель Х-образный – крест, предназначен для организации ответвления кабельной трассы в обе стороны (перекресток)',
            'угол горизонтальный регулируемый – предназначен для поворота трассы в горизонтальной плоскости с возможностью регулирования угла от 0 до 45 град',
            'переходник по ширине – предназначен для перехода трассы от одного типоразмера лотка к другому',
            'переходник по высоте – предназначен для перехода трассы от одного типоразмера лотка к другому',
            'крышка кабельного лотка – предназначена для защиты кабельной трассы',
            'крышка углов и ответвителей – предназначена для защиты кабельной трассы',
            'соединитель кабельного лотка – предназначен для соединения лотков между собой',
            'фиксаторы – предназначены для дополнительной фиксации'
        ],
        dopText: 'Углы, ответвители и переходники, имеют неперфорированную конструкцию и используются как для перфорированных и неперфорированных лотков серии Стандарт, так и для лотков лестничного типа.',
        table: {
            sz: true,
            isLength: true,
            isThikness1: true,
            stal: false,
            tu: 'ТУ-3449-001-90908045-2017',
            length: '2.0 м, 2.5 м, 3.0 м',
            thinkness1: '1.0 мм, 1.2 мм, 1.5 мм',
            thinkness2: '1.0 мм, 1.5 мм'
        },
        contsruct: {
            text: 'Аксессуары не имеют острых граней',
            images: [
                '/img/acc-stand1.jpg'
            ]
        },
        variants: [
            '/img/ac2_1.jpg',
            '/img/ac2_2.jpg',
            '/img/ac2_3.jpg'
        ]
    },

    {
        name: 'optima',
        seriaName: "Оптима",
        text: 'Аксессуары серии «ОПТИМА» предназначены для монтажа поворотов, ответвлений и защиты кабельной трассы. В их число входят:',
        accessoriesList: [
            'угол горизонтальный 90 град. – предназначен для поворота трассы в горизонтальной плоскости',
            'угол горизонтальный 45 град. – предназначен для поворота трассы в горизонтальной плоскости',
            'угол вертикальный вверх 90 град. – предназначен для поворота трассы вверх',
            'угол вертикальный вверх 45 град. – предназначен для поворота трассы вверх',
            'угол вертикальный вниз 90 град. – предназначен для поворота трассы вниз',
            'угол вертикальный вниз 45 град. – предназначен для поворота трассы вниз',
            'ответвитель Т-образный – предназначен для организации Т-образного отвода',
            'ответвитель Х-образный –  крест, предназначен для организации ответвления кабельной трассы в обе стороны (перекресток)',
            'угол горизонтальный регулируемый – предназначен для поворота трассы в горизонтальной плоскости с возможностью регулирования угла от 0 до 45 град',
            'переходник по ширине – предназначен для перехода трассы от одного типоразмера лотка к другому',
            'переходник по высоте – предназначен для перехода трассы от одного типоразмера лотка к другому',
            'крышка кабельного лотка – предназначена для защиты кабельной трассы',
            'крышка углов и ответвителей – предназначена для защиты кабельной трассы',
            'соединитель кабельного лотка – предназначен для соединения лотков между собой',
            'фиксаторы – предназначены для дополнительной фиксации'
        ],
        dopText: 'Углы, ответвители и переходники, имеют неперфорированную конструкцию и используются как для перфорированных и неперфорированных лотков серии Оптима, так и для лотков лестничного типа.',
        table: {
            sz: true,
            isLength: true,
            isThikness1: true,
            stal: true,
            tu: 'ТУ-3449-002-90908045-2017',
            length: '2.0 м, 2.5 м, 3.0 м',
            thinkness1: '1.0 мм, 1.2 мм, 1.5 мм',
            thinkness2: '1.0 мм, 1.2 мм, 1.5 мм'
        },
        contsruct: {
            text: '2 варианта исполнения аксессуаров: с радиусом 100мм и 300мм',
            images: [
                '/img/acc-optima1.jpg'
            ]
        },
        variants: [
            '/img/ac1.jpg',
            '/img/ac2.jpg',
            '/img/ac3.jpg'
        ]
    },

    {
        name: 'maks',
        seriaName: "Макс",
        text: 'Аксессуары серии «МАКС» предназначены для монтажа поворотов, ответвлений и защиты кабельной трассы. В их число входят:',
        accessoriesList: [
            'угол горизонтальный 90 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы в горизонтальной плоскости',
            'угол горизонтальный 60 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы в горизонтальной плоскости',
            'угол горизонтальный 45 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы в горизонтальной плоскости',
            'угол горизонтальный 30 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы в горизонтальной плоскости',
            'угол вертикальный вверх 90 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы вверх',
            'угол вертикальный вверх 45 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы вверх',
            'угол вертикальный вниз 90 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы вниз',
            'угол вертикальный вниз 45 град. с радиусом 600мм, 900мм и 1500 мм – предназначен для поворота трассы вниз',
            'ответвитель Т-образный с радиусом 600мм, 900мм и 1500 мм – предназначен для организации Т-образного отвода',
            'крышка кабельного лотка – предназначена для защиты кабельной трассы',
            'крышка углов и ответвителей – предназначена для защиты кабельной трассы',
            'соединитель кабельного лотка – предназначен для соединения лотков между собой',
            'фиксаторы – предназначены для дополнительной фиксации'
        ],
        dopText: 'Углы, ответвители и переходники, имеют конструкцию лестничного типа.',
        table: {
            sz: false,
            isLength: false,
            isThikness1: false,
            stal: true,
            tu: 'ТУ-3449-005-90908045-2017',
            length: '2.0 м, 2.5 м, 3.0 м',
            thinkness1: '1.0 мм, 1.2 мм, 1.5 мм',
            thinkness2: '2.0 мм'
        },
        contsruct: {
            text: '3 варианта исполнения аксессуаров: с радиусом 600мм, 900мм и 1500мм',
            images: [
                '/img/acc-maks1.jpg'
            ]
        },
        variants: [
            '/img/ac3_1.jpg',
            '/img/ac3_2.jpg',
            '/img/ac3_3.jpg'
        ]
    },

]