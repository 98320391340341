<template>
  <div id="app">
    <header class="header">
        <div class="container header__container">
            <div class="menu hided">
                <ul class="menu__list">
                    <li class="menu__item">
                        <router-link to="/" class="menu__link">ГЛАВНАЯ</router-link>
                    </li>
                    <li class="menu__item">
                        <router-link :to="{name: 'products'}" class="menu__link">ПРОДУКЦИЯ</router-link>
                    </li>
                    <li class="menu__item">
                        <router-link :to="{name: 'services'}" class="menu__link">УСЛУГИ</router-link>
                    </li>
                    <li class="menu__item">
                        <router-link :to="{name: 'projects'}" class="menu__link">ПРОЕКТЫ</router-link>
                    </li>
                    <li class="menu__item">
                        <router-link :to="{name: 'info'}" class="menu__link">ПРОЕКТИРОВЩИКУ</router-link>
                    </li>
                </ul>
            </div>
            <a href="/" class="header__logo-link"><img src="/img/logo.png" alt="" class="header__logo"></a>
            <ul class="header__menu-list">
                <li class="header__menu-item">
                    <router-link to="/" class="header__menu-link header__menu-main">ГЛАВНАЯ</router-link>
                </li>
                <li class="header__menu-item">
                    <router-link :to="{name: 'products'}" class="header__menu-link header__menu-products">ПРОДУКЦИЯ</router-link>
                </li>
                <li class="header__menu-item">
                    <router-link :to="{name: 'services'}" class="header__menu-link header__menu-services">УСЛУГИ</router-link>
                </li>
                <li class="header__menu-item">
                    <router-link :to="{name: 'projects'}" class="header__menu-link">ПРОЕКТЫ</router-link>
                </li>
                <li class="header__menu-item">
                    <router-link :to="{name: 'info'}" class="header__menu-link">ПРОЕКТИРОВЩИКУ</router-link>
                </li>
            </ul>
            <div class="header__menu-logo">
                <a href="https://yandex.ru/maps/org/versus/142584147856/?ll=65.677193%2C57.094931&z=16.79" target="blank" class="header__location">
                    <svg class="header__svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        width="30" height="30" viewBox="0 0 395.71 395.71" style="enable-background:new 0 0 395.71 395.71;"
                        xml:space="preserve">
                        <g>
                            <path fill="#D81E36" d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
                            c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
                            C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
                            c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z"/>
                        </g>
                    </svg>
                </a>
                <a href="tel:8 (3452) 50-00-63" class="header__phone">
                    <svg width="30" height="30" class="header__svg" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 53.942 53.942" style="enable-background:new 0 0 53.942 53.942;" xml:space="preserve">
                        <g>
                            <path fill="#D81E36" d="M53.364,40.908c-2.008-3.796-8.981-7.912-9.288-8.092c-0.896-0.51-1.831-0.78-2.706-0.78c-1.301,0-2.366,0.596-3.011,1.68
                            c-1.02,1.22-2.285,2.646-2.592,2.867c-2.376,1.612-4.236,1.429-6.294-0.629L17.987,24.467c-2.045-2.045-2.233-3.928-0.632-6.291
                            c0.224-0.309,1.65-1.575,2.87-2.596c0.778-0.463,1.312-1.151,1.546-1.995c0.311-1.123,0.082-2.444-0.652-3.731
                            c-0.173-0.296-4.291-7.27-8.085-9.277c-0.708-0.375-1.506-0.573-2.306-0.573c-1.318,0-2.558,0.514-3.49,1.445L4.7,3.986
                            c-4.014,4.013-5.467,8.562-4.321,13.52c0.956,4.132,3.742,8.529,8.282,13.068l14.705,14.705c5.746,5.746,11.224,8.66,16.282,8.66
                            c0,0,0,0,0,0c3.721,0,7.188-1.581,10.306-4.698l2.537-2.537C54.033,45.163,54.383,42.833,53.364,40.908z"/>
                            <path fill="#D81E36" d="M29.56,24.926c0.122,0.051,0.252,0.077,0.382,0.077h12c0.552,0,1-0.447,1-1s-0.448-1-1-1h-9.586L47.649,7.71
                            c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0L30.942,21.589v-9.586c0-0.553-0.448-1-1-1s-1,0.447-1,1v12
                            c0,0.13,0.027,0.26,0.077,0.382C29.121,24.63,29.316,24.825,29.56,24.926z"/>
                        </g>
                    </svg>
                </a>
                <button class="header__button" v-on:click="show()">
                    <svg class="header__button-svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18L20 18" stroke="#D81E36" stroke-width="2" stroke-linecap="round"/>
                    <path d="M4 12L20 12" stroke="#D81E36" stroke-width="2" stroke-linecap="round"/>
                    <path d="M4 6L20 6" stroke="#D81E36" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </button>
            </div>
            
        </div>
    </header>
    <router-view/>
    <footer class="footer">
        <div class="footer__border"></div>
        <div class="footer__content">
            <div class="footer__container container">
                <div class="footer__contact">
                    <img class="footer__logo" src="/img/logo.png" alt="">
                    <div class="footer__contact-blok">
                        <h3 class="footer__contact-header">Позвонить</h3>
                        <a href="tel:8 (3452) 50-00-63" class="footer__number">8 <span style="font-family: sans-serif;">(</span>3452<span style="font-family: sans-serif;">)</span> 50-00-63</a>
                        <div class="footer__number-time">Пн – Пт: с 9:00 до 18:00</div>
                    </div>
                    <div class="footer__contact-blok">
                        <h3 class="footer__contact-header footer__soc-header">Написать</h3>
                        <!-- <a href="mailto:sale@versus-cs.ru" class="footer__mail">sale@versus-cs.ru</a> -->
                        <div class="footer__soc-list">
                            <a href="mailto:info@versus-cs.ru" target="blank" class="footer__soc-link">
                                <img src="/img/mail.png" alt="" class="footer__soc-image">
                            </a>
                            <a href="viber://chat?number=%2B79292605802" target="blank" class="footer__soc-link">
                                <img src="/img/viber.png" alt="" class="footer__soc-image">
                            </a>
                            <a href="https://wa.me/79292605802" target="blank" class="footer__soc-link">
                                <img src="/img/whatsapp.svg" alt="" class="footer__soc-image">
                            </a>
                            <a href="https://t.me/pbyakhov" target="blank" class="footer__soc-link">
                                <img src="/img/telegram.svg" alt="" class="footer__soc-image">
                            </a>
                        </div>
                    </div>
                </div>
                <yandexMap class="footer__map" :settings="settings" :zoom="14" :coords="[57.09370262679214,65.67333976643754]">
                    <ymapMarker :icon="markerIcon" :marker-id="1" :coords="[57.09370262679214,65.67333976643754]"></ymapMarker>
                </yandexMap>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
    import { yandexMap, ymapMarker } from 'vue-yandex-maps'

    export default {
        components: { yandexMap, ymapMarker },
        data: () => ({
            coords: [54, 39],
            markerIcon: {
                layout: 'default#image',
                imageHref: 'img/mark.svg',
                imageSize: [20, 20],
                imageOffset: [-10, -10],
            },
            settings: {
                apiKey: '0',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            }
        }),

        methods: {
            show: function(event) {
                let menu = document.querySelector('.menu')
                menu.classList.toggle('hided')
            }
        }
    }
</script>