var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"elements"},[_c('div',{staticClass:"container elements__container"},[_c('ul',{staticClass:"product__list-links"},[_c('li',{staticClass:"product__item-links"},[_c('router-link',{staticClass:"product__links-link",attrs:{"to":{name: 'products'}}},[_vm._v("Продукция")])],1),_vm._m(0)]),_c('h2',{staticClass:"elements__header"},[_vm._v("Универсальные элементы лотков")]),_vm._m(1),_c('h3',{staticClass:"elements__tech-header"},[_vm._v("Технические характеристики")]),_vm._m(2),_c('h3',{staticClass:"elements__os-header"},[_vm._v("Особенности соединителя защитного")]),_vm._m(3),_vm._m(4),_c('h3',{staticClass:"elements__products-header"},[_vm._v("Примеры элементов")]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"product__item-links"},[_c('div',{staticClass:"product__links-link"},[_vm._v("Универсальные элементы лотков")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elements__desc-container"},[_c('p',{staticClass:"elements__desc-text"},[_vm._v(" Универсальные элементы кабеленесущей системы применимы к лоткам серий «СТАНДАРТ» и «ОПТИМА». В их число входят: ")]),_c('ul',{staticClass:"elements__desc-list"},[_c('li',{staticClass:"elements__desc-item"},[_vm._v("Перегородки разделительные для кабельного лотка")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Заглушки для кабельного лотка")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Соединители шарнирные")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Соединители защитные")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Соединители усиленные")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Скобы")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Фиксаторы")]),_c('li',{staticClass:"elements__desc-item"},[_vm._v("Пластины заземления")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"seria__tech-table"},[_c('tbody',{staticClass:"seria__table-body"},[_c('tr',{staticClass:"seria__table-line"},[_c('td',{staticClass:"seria__table-cell"},[_vm._v("ТУ")]),_c('td',{staticClass:"seria__table-cell"},[_c('ul',{staticClass:"seria__table-tu-list"},[_c('li',{staticClass:"seria__table-tu-item"},[_vm._v("ТУ-3449-001-90908045-2017")]),_c('li',{staticClass:"seria__table-tu-item"},[_vm._v("ТУ-3449-002-90908045-2017")])])])]),_c('tr',{staticClass:"seria__table-line"},[_c('td',{staticClass:"seria__table-cell"},[_vm._v("Материалы и покрытия")]),_c('td',{staticClass:"seria__table-cell"},[_c('ul',{staticClass:"seria__table-list"},[_c('li',{staticClass:"seria__table-item"},[_c('div',{staticClass:"seria__table-item-header"},[_vm._v("Горячее цинкование методом Сендзимира")])]),_c('li',{staticClass:"seria__table-item"},[_c('div',{staticClass:"seria__table-item-header"},[_vm._v("Горячее цинкование методом погружения")])]),_c('li',{staticClass:"seria__table-item"},[_c('div',{staticClass:"seria__table-item-header"},[_vm._v("Порошковая покраска")]),_c('div',{staticClass:"seria__table-item-text"},[_vm._v("По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.")])]),_c('li',{staticClass:"seria__table-item"},[_c('div',{staticClass:"seria__table-item-header"},[_vm._v("Нержавеющая сталь")]),_c('div',{staticClass:"seria__table-item-text"},[_vm._v("Сталь нержавеющая марки AISI 304L, AISI 316L")])])])])]),_c('tr',{staticClass:"seria__table-line"},[_c('td',{staticClass:"seria__table-cell"},[_vm._v("Климатическое исполнение")]),_c('td',{staticClass:"seria__table-cell"},[_vm._v("УХЛ1")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elements__os-container"},[_c('p',{staticClass:"elements__os-text"},[_vm._v("Используется в местах соединения лотков в дополнение к соединителям. Предотвращает прогибание нижней части лотка и повреждение кабелей о нижнюю часть лотка, а также дополнительно усиливает место соединения. Не требует метизов для установки.")]),_c('img',{staticClass:"elements__os-img",attrs:{"src":"/img/os1.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"elements__os-list"},[_c('li',{staticClass:"elements__os-item"},[_c('img',{staticClass:"elements__os-item-img",attrs:{"src":"/img/os2.jpg","alt":""}})]),_c('li',{staticClass:"elements__os-item"},[_c('img',{staticClass:"elements__os-item-img",attrs:{"src":"/img/os3.jpg","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"elements__products-list elements__products-list-el"},[_c('li',{staticClass:"elements__products-item elements__products-item-el"},[_c('img',{staticClass:"elements__products-img",attrs:{"src":"/img/el2.jpg","alt":""}})]),_c('li',{staticClass:"elements__products-item elements__products-item-el"},[_c('img',{staticClass:"elements__products-img",attrs:{"src":"/img/el1.jpg","alt":""}})]),_c('li',{staticClass:"elements__products-item elements__products-item-el"},[_c('img',{staticClass:"elements__products-img",attrs:{"src":"/img/el3.jpg","alt":""}})]),_c('li',{staticClass:"elements__products-item elements__products-item-el"},[_c('img',{staticClass:"elements__products-img",attrs:{"src":"/img/el4.jpg","alt":""}})])])
}]

export { render, staticRenderFns }