<template>
    <div>
        <section class="elements">
            <div class="container elements__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Универсальные элементы лотков</div>
                    </li>
                </ul>
                <h2 class="elements__header">Универсальные элементы лотков</h2>
                <div class="elements__desc-container">
                    <p class="elements__desc-text">
                        Универсальные элементы кабеленесущей системы применимы к лоткам серий «СТАНДАРТ» и «ОПТИМА». В их число входят:
                    </p>
                    <ul class="elements__desc-list">
                        <li class="elements__desc-item">Перегородки разделительные для кабельного лотка</li>
                        <li class="elements__desc-item">Заглушки для кабельного лотка</li>
                        <li class="elements__desc-item">Соединители шарнирные</li>
                        <li class="elements__desc-item">Соединители защитные</li>
                        <li class="elements__desc-item">Соединители усиленные</li>
                        <li class="elements__desc-item">Скобы</li>
                        <li class="elements__desc-item">Фиксаторы</li>
                        <li class="elements__desc-item">Пластины заземления</li>
                    </ul>
                </div>

                <h3 class="elements__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-tu-list">
                                    <li class="seria__table-tu-item">ТУ-3449-001-90908045-2017</li>
                                    <li class="seria__table-tu-item">ТУ-3449-002-90908045-2017</li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Нержавеющая сталь</div>
                                        <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>

                <h3 class="elements__os-header">Особенности соединителя защитного</h3>
                <div class="elements__os-container">
                    <p class="elements__os-text">Используется в местах соединения лотков в дополнение к соединителям. Предотвращает прогибание нижней части лотка и повреждение кабелей о нижнюю часть лотка, а также дополнительно усиливает место соединения. Не требует метизов для установки.</p>
                    <img src="/img/os1.jpg" alt="" class="elements__os-img">
                </div>
                <ul class="elements__os-list">
                    <li class="elements__os-item">
                        <img src="/img/os2.jpg" alt="" class="elements__os-item-img">
                    </li>
                    <li class="elements__os-item">
                        <img src="/img/os3.jpg" alt="" class="elements__os-item-img">
                    </li>
                </ul>

                <h3 class="elements__products-header">Примеры элементов</h3>
                <ul class="elements__products-list elements__products-list-el">
                    <li class="elements__products-item elements__products-item-el">
                        <img src="/img/el2.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item elements__products-item-el">
                        <img src="/img/el1.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item elements__products-item-el">
                        <img src="/img/el3.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item elements__products-item-el">
                        <img src="/img/el4.jpg" alt="" class="elements__products-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>