<template>
    <div>
        <section class="accessories">
            <div class="container accessories__container">
                <ul class="product__list-links products__hleb">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <router-link :to="{name: accessory.name}" class="product__links-link">Серия <span class="normalfont">"</span>{{ accessory.seriaName }}<span class="normalfont">"</span></router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Аксессуары</div>
                    </li>
                </ul>
                <h2 class="accessories__header">Аксессуары серии "{{ accessory.seriaName }}"</h2>
                <div class="accessories__main">
                    
                    <p class="accessories__text">{{ accessory.text }}</p>

                    <ul class="accessories__list">
                        <li v-for="ac in accessory.accessoriesList" class="accessories__item">{{ ac }}</li>
                    </ul>
                </div>
                <p class="accessories__desc">{{ accessory.dopText }}</p>

                <h3 class="accessories__tech-header">Технические характеристики</h3>

                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">{{ accessory.table.tu }}</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li v-if="accessory.table.sz" class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                    <li v-if="accessory.table.stal" class="seria__table-item">
                                        <div class="seria__table-item-header">Нержавеющая сталь</div>
                                        <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line" v-if="accessory.table.isLength">
                            <td class="seria__table-cell">Длина крышек лотков</td>
                            <td class="seria__table-cell">{{ accessory.table.length }}</td>
                        </tr>
                        <tr class="seria__table-line" v-if="accessory.table.isThikness1">
                            <td class="seria__table-cell">Толщина металла для крышек лотков</td>
                            <td class="seria__table-cell">{{ accessory.table.thinkness1 }}</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла для углов, ответвителей, переходников</td>
                            <td class="seria__table-cell">{{ accessory.table.thinkness2 }}</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>

                <h3 class="accessories__constr-header">Конструктивные особенности</h3>
                <p class="accessories__constr-text">{{ accessory.contsruct.text }}</p>
                <ul class="accessories__constr-list">
                    <li v-for="im in accessory.contsruct.images" class="accessories__constr-item">
                        <img :src="im" alt="" class="accessories__constr-img">
                    </li>
                </ul>

                <h3 class="accessories__variant-header">Варианты исполнения</h3>
                <ul class="seria__accses-img-list">
                    <li v-for="im in accessory.variants" class="seria__accses-img-item">
                        <img :src="im" alt="" class="seria__accses-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import accessories from '../components/accessories';

export default {
    mounted() {
        window.scrollTo(0,0)
    },
    data() {
        return {
            accessory: accessories.find(a => a.name === this.$route.params.seria)
        }
    }
}
</script>