export default [
    {
        name: 'standard-perf',
        image: '/img/optima1.jpg',
        seria: 'Стандарт',
        seriaLink: 'standard',
        title: 'Лоток перфорированный',
        length: '2.0 м, 2.5 м, 3.0 м',
        thickness: '0.7 мм, 1 мм, 1.2 мм, 1.5 мм',
        aisi: false,
        sz: true,
        table: {
            rowsCount: 4,
            colsCount: 6,
            rows: [
                [50, true, true, true, true, true, true],
                [65, false, true, true, true, true, true],
                [80, false, true, true, true, true, true],
                [100, false, true, true, true, true, true]
            ],
            cols: [50, 150, 100, 200, 300, 400]
        }
    },
    {
        name: 'standard-neperf',
        image: '/img/optima2.jpg',
        seria: 'Стандарт',
        seriaLink: 'standard',
        title: 'Лоток неперфорированный',
        length: '2.0 м, 2.5 м, 3.0 м',
        thickness: '0.7 мм, 1 мм, 1.2 мм, 1.5 мм',
        aisi: false,
        sz: true,
        table: {
            rowsCount: 4,
            colsCount: 6,
            rows: [
                [50, true, true, true, true, true, true],
                [65, false, true, true, true, true, true],
                [80, false, true, true, true, true, true],
                [100, false, true, true, true, true, true]
            ],
            cols: [50, 150, 100, 200, 300, 400]
        }
    },
    {
        name: 'standard-lest',
        image: '/img/optima3.jpg',
        seria: 'Стандарт',
        seriaLink: 'standard',
        title: 'Лестничный лоток',
        length: '1.8 м, 2.0 м, 2.4 м',
        thickness: '1.2 мм, 1.5 мм',
        aisi: false,
        sz: true,
        table: {
            rowsCount: 3,
            colsCount: 5,
            rows: [
                [50, true, true, true, true, true],
                [70, true, true, true, true, true],
                [100, true, true, true, true, true]
            ],
            cols: [100, 150, 200, 300, 400]
        }
    },

    {
        name: 'optima-perf',
        image: '/img/optima1.jpg',
        seria: 'Оптима',
        seriaLink: 'optima',
        title: 'Лоток перфорированный',
        aisi: true,
        length: '2.0 м, 2.5 м, 3.0 м',
        thickness: '0.7 мм, 1 мм, 1.2 мм, 1.5 мм',
        sz: true,
        table: {
            rowsCount: 4,
            colsCount: 8,
            rows: [
                [50, true, true, true, true, true, true, true, true],
                [60, false, true, true, true, true, true, true, true],
                [80, false, true, true, true, true, true, true, true],
                [100, false, true, true, true, true, true, true, true]
            ],
            cols: [50, 150, 100, 200, 300, 400, 500, 600]
        }
    },
    {
        name: 'optima-neperf',
        image: '/img/optima2.jpg',
        seria: 'Оптима',
        seriaLink: 'optima',
        title: 'Лоток неперфорированный',
        length: '2.0 м, 2.5 м, 3.0 м',
        thickness: '0.7 мм, 1 мм, 1.2 мм, 1.5 мм',
        aisi: true,
        sz: true,
        table: {
            rowsCount: 4,
            colsCount: 8,
            rows: [
                [50, true, true, true, true, true, true, true, true],
                [60, false, true, true, true, true, true, true, true],
                [80, false, true, true, true, true, true, true, true],
                [100, false, true, true, true, true, true, true, true]
            ],
            cols: [50, 150, 100, 200, 300, 400, 500, 600]
        }
    },
    {
        name: 'optima-lest',
        image: '/img/optima3.jpg',
        seria: 'Оптима',
        seriaLink: 'optima',
        title: 'Лестничный лоток',
        length: '2.4 м, 3.0 м',
        thickness: '1.2 мм, 1.5 мм',
        aisi: false,
        sz: true,
        table: {
            rowsCount: 3,
            colsCount: 7,
            rows: [
                [50, true, true, true, true, true, true, true],
                [80, true, true, true, true, true, true, true],
                [100, true, true, true, true, true, true, true]
            ],
            cols: [100, 150, 200, 300, 400, 500, 600]
        }
    },

    {
        name: 'maks-lest',
        image: '/img/optima3.jpg',
        seria: 'Макс',
        seriaLink: 'maks',
        title: 'Лестничный лоток',
        length: '3.0 м, 6.0 м',
        thickness: '2.0 мм',
        aisi: true,
        sz: false,
        table: {
            rowsCount: 1,
            colsCount: 4,
            rows: [
                [100, true, true, true, true],
            ],
            cols: [300, 450, 600, 900]
        }
    },

];