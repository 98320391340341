<template>
    <div>
        <section class="product" :style="styleObj">
            <div class="container product__container">
                <ul class="product__list-links products__hleb">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <router-link :to="{name: data.seriaLink}" class="product__links-link">Серия <span class="normalfont">"</span>{{ data.seria }}<span class="normalfont">"</span></router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">{{ data.title }}</div>
                    </li>
                </ul>
                <h2 class="product__header">{{data.title}} {{ data.seria }}</h2>
                <p class="product__text">Лоток перфорированный может быть изготовлен в следующих вариантах:</p>
                <ul class="product__list">
                    <li class="product__item">
                        Длина лотка: {{data.length}}
                    </li>
                    <li class="product__item">
                        Толщина лотка: {{data.thickness}}
                    </li>
                    <li v-if="data.sz" class="product__item">
                        Горячее цинкование методом Сендзимира <span class="normalfont">(</span>SZ<span class="normalfont">)</span>
                    </li>
                    <li class="product__item">
                        Горячее цинкование методом погружения <span class="normalfont">(</span>HD<span class="normalfont">)</span>
                    </li>
                    <li class="product__item">
                        Порошковая покраска <span class="normalfont">(</span>по требованию Заказчика<span class="normalfont">)</span>
                    </li>
                    <li v-if="data.aisi" class="product__item">
                        Нержавеющая сталь марки AISI 304L, AISI 316L
                    </li>
                    <li class="product__item">
                        <div class="product__item-header">Исполнение покрытия:</div>
                        <table class="product__table">
                            <thead class="product__thead">
                                <tr class="product__row-head product__row">
                                    <th class="product__col-head" rowspan="2">Высота боковой стенки лотка, мм</th>
                                    <th class="product__col-head" :colspan="data.table.colsCount">Ширина лотка, мм</th>
                                </tr>
                                <tr class="product__row">
                                    <th class="product__w-head" v-for="col in data.table.cols">{{ col }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="row in data.table.rows" class="product__row">
                                    <td v-for="col in row" class="product__column">
                                        <div v-if="col === true || !col" class="normalfont product__col-text">
                                            <img v-if="col" src='/img/yes.svg' alt="" class="product__yesorno product__yes">
                                            <img v-else src='/img/no.svg' alt="" class="product__yesorno product__no">
                                        </div>
                                        <span v-else class="product__col-text">{{ col }}</span>
                                    </td>
                                </tr>     
                            </tbody>
                        </table>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import data from '../components/data'

export default {
    name: 'Product',
    mounted() {
        window.scrollTo(0,0)
    },
    data() {
        return {
            data: data.find(p => p.name === this.$route.params.name),
            styleObj: {
                backgroundImage: 'url("' + data.find(p => p.name === this.$route.params.name).image + '")',
            }
        }
    }
}
</script>
