export default [
    {
        name: 'U',
        description: 'Опорные конструкции с U-образными стойками по своим характеристикам позволяют заменить изделия ГЭМ, но в то же время болтовые соединения увеличивают допустимую нагрузку.',
        image: '/img/type1.jpg',
        table: {
            tu: 'ТУ-3449-003-90908045-2017',
            minLen: '400 мм',
            maxLen: '2500 мм',
            workLen: '100...500 мм',
            thinkhessSt: '2.0 мм, 2.5 мм, 3.0 мм',
            thinkhessKr: '1.5 мм, 2.0 мм, 2.5 мм',
            stal: false
        },
        variants: [
            '/img/op1_1.jpg',
            '/img/op1_2.jpg',
            '/img/op1_3.jpg'
        ]
    },
    {
        name: 'Z',
        description: 'Опорные конструкции с Z-образными стойками рассчитаны на высокие нагрузки. На одну стойку возможен как односторонний, так и двухсторонний монтаж кабельных кронштейнов.',
        image: '/img/type3.jpg',
        table: {
            tu: 'ТУ-3449-004-90908045-2017',
            minLen: '500 мм',
            maxLen: '2500 мм',
            workLen: '275…675 мм',
            thinkhessSt: '2.0 мм, 2.5 мм, 3.0 мм',
            thinkhessKr: '2.0 мм',
            stal: false
        },
        variants: [
            '/img/op3_1.jpg',
            '/img/op3_2.jpg',
        ]
    },
    {
        name: 'K',
        description: 'Опорные конструкции типа K по своим характеристикам аналогичны изделиям, выпускаемым заводами Главэлектромонтаж (ГЭМ).',
        image: '/img/typeK.png',
        table: {
            tu: 'ТУ-3449-003-90908045-2017',
            minLen: '100 мм',
            maxLen: '2500 мм',
            workLen: '100…400 мм',
            thinkhessSt: '2.0 мм, 2.5 мм',
            thinkhessKr: '1.5 мм, 2.0 мм',
            stal: false
        },
        variants: [
            '/img/k1.png',
            '/img/k2.png',
            '/img/k3.png'
        ]
    }
]