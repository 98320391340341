<template> 
    <div>
        <section class="seria">
            <div class="container seria__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Серия <span class="normalfont">"</span>Стандарт<span class="normalfont">"</span></div>
                    </li>
                </ul>
                <h2 class="seria__header">Серия "Стандарт"</h2>
                <div class="seria__desc-container">
                    <p class="seria__desc-text"><strong>Серия «СТАНДАРТ»</strong> представляет собой классическую систему кабельных лотков. Выпускается в перфорированном, неперфорированном варианте, лестничного типа. Могут комплектоваться крышкой.</p>
                    <p class="seria__desc-text">Лотки этой серии могут применяться для создания энергосетей до 1000В в промышленности, на нефтяном промысле, в коммерческом и жилом строительстве. По своим характеристикам система позволяет заменить изделия, выпускаемые заводами Главэлектромонтаж (ГЭМ).</p>
                </div>
                <h3 class="seria__products-header">Продукция</h3>
                <ul class="seria__products-list">
                    <li class="seria__products-item">
                        <img src="/img/stand1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Лоток перфорированный</h4>
                            <router-link class="seria__products-link" :to="{name: 'product-standard', params: {name: 'standard-perf'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item">
                        <img src="/img/stand2.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Лоток неперфорированный</h4>
                            <router-link class="seria__products-link" :to="{name: 'product-standard', params: {name: 'standard-neperf'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item">
                        <img src="/img/stand3.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Лестничный лоток</h4>
                            <router-link class="seria__products-link" :to="{name: 'product-standard', params: {name: 'standard-lest'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item">
                        <img src="/img/ac2_1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Аксессуары</h4>
                            <router-link class="seria__products-link" :to="{name: 'seria-accessories', params: {seria: 'standard'}}">Подробнее</router-link>
                        </div>
                    </li>
                </ul>

                <h3 class="seria__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-001-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Длина перфорированных и неперфорированных лотков</td>
                            <td class="seria__table-cell">2.0 м, 2.5 м, 3.0 м</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Длина лотков лестничного типа</td>
                            <td class="seria__table-cell">1.8 м, 2.0 м, 2.4 м</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла</td>
                            <td class="seria__table-cell">0.7 мм, 1.0 мм, 1.2 мм, 1.5 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>

                <h3 class="seria__good-header">Конструктивные особенности</h3>
                <ul class="seria__good-list">
                    <li class="seria__good-item">
                        Неперфорированные лотки имеют перфорацию для установки соединителей
                    </li>
                    <li class="seria__good-item">
                        Лотки имеют загнутую кромку для предотвращения повреждений персонала и кабелей, а также повышения прочности.
                    </li>
                    <li class="seria__good-item">
                        Крышки лотков имеют по два ребра жесткости с каждой стороны для повышения прочности
                    </li>
                    <li class="seria__good-item">
                        Конструкция соединителей и кромка лотка обеспечивают надежное соединение даже при незатянутых болтах
                    </li>
                    <li class="seria__good-item">
                        Размер отверстий и шаг перфорации лотков и кронштейнов обеспечивают совпадение минимум двух отверстий для крепления лотка
                    </li>
                    <li class="seria__good-item">
                        Возможна дополнительная фиксация крышки при помощи специальных фиксаторов
                    </li>
                </ul>
                <ul class="seria__good-img-list">
                    <li class="seria__good-img-item">
                        <img src="/img/good2_1.jpg" alt="" class="seria__good-item-img">
                    </li>
                    <li class="seria__good-img-item">
                        <img src="/img/good2_2.jpg" alt="" class="seria__good-item-img">
                    </li>
                    <li class="seria__good-img-item">
                        <img src="/img/good2_3.jpg" alt="" class="seria__good-item-img">
                    </li>
                    <li class="seria__good-img-item">
                        <img src="/img/good2_4.jpg" alt="" class="seria__good-item-img">
                    </li>
                </ul>
                <img src="/img/good2_5.jpg" alt="" class="seria__good-img">
                <h3 class="seria__accses-header">Монтаж серии лотков</h3>
                <p class="seria__accses-text">Монтаж серии лотков Стандарт производится с использованием различных секций и аксессуаров, таких как:</p>
                <ul class="seria__accses-list">
                    <li class="seria__acces-item">Углы</li>
                    <li class="seria__acces-item">Ответвители</li>
                    <li class="seria__acces-item">Крышки</li>
                    <li class="seria__acces-item">Соединители и др.</li>
                </ul>
                <ul class="seria__accses-img-list">
                    <li class="seria__accses-img-item">
                        <img src="/img/ac2_1.jpg" alt="" class="seria__accses-img">
                    </li>
                    <li class="seria__accses-img-item">
                        <img src="/img/ac2_2.jpg" alt="" class="seria__accses-img">
                    </li>
                    <li class="seria__accses-img-item">
                        <img src="/img/ac2_3.jpg" alt="" class="seria__accses-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>