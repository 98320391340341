<template>
    <div>
        <section class="hero">
            <div class="container hero__container">
                <p class="hero__text">Производство и&nbsp;продажа элементов кабеленесущих конструкций</p>
                <a href="#call" class="hero__btn">Оставить заявку</a>
            </div>
            <div class="hero__background"></div>
        </section>
        <section class="about">
            <div class="container about__container">
                <h2 class="title about__header">О нас</h2>
                <p class="about__text">ООО «Версус»&nbsp;—&nbsp;российская компания&nbsp;-&nbsp;производитель кабеленесущих систем. Наша&nbsp;цель&nbsp;-&nbsp;изготавливать первоклассную продукцию, которая отвечает самым современным требованиям мировых стандартов, а также предоставлять Заказчикам высочайший сервис и индивидуальный подход. Компания работает на рынке более 8 лет. На предприятии реализован полный цикл производства с контролем качества на каждом этапе.</p>
            </div>
        </section>
        <section class="super">
            <div class="container super__container">
                <h2 class="title super__header">Наши преимущества</h2>
                <div class="super__list">
                    <div class="super__item">
                        <div class="super__item-header">
                            <img src="img/super1.png" alt="" class="super__item-img">
                            <h3 class="super__item-head">Увеличенное ребро жесткости у крышки лотков</h3>
                        </div>
                        <p class="super__item-text">Крышка лотков имеет увеличенное ребро жесткости, что позволяет крышке, даже при длине 3 метра не получать необратимые изгибы под собственным весом в процессе неправильной переноски при монтаже.</p>
                    </div>
                    <div class="super__item">
                        <div class="super__item-header">
                            <img src="img/super2.png" alt="" class="super__item-img">
                            <h3 class="super__item-head">Особая конструкция соединителей лотков</h3>
                        </div>
                        <p class="super__item-text">Соединители лотков сконструированы таким образом, что даже при незатянутых винтах сохраняют 80% несущей способности.</p>
                    </div>
                    <div class="super__item">
                        <div class="super__item-header">
                            <img src="img/super3.png" alt="" class="super__item-img">
                            <h3 class="super__item-head">Перфорация по всей длине борта</h3>
                        </div>
                        <p class="super__item-text">Размеры и расположение перфорации перфорированных и лестничных лотков позволяют установить соединитель в любом месте реза лотка без необходимости сверления дополнительных отверстий.</p>
                    </div>
                    <div class="super__item">
                        <div class="super__item-header">
                            <img src="img/super4.png" alt="" class="super__item-img">
                            <h3 class="super__item-head">Индивидуальная упаковка</h3>
                        </div>
                        <p class="super__item-text">Мы изготавливаем необходимую упаковку, соответствующую всем требованиям Заказчика, и осуществляем отгрузку любого количества продукции с доставкой по всей России.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="partners">
            <div class="container partners__container">
                <h2 class="title partners__header">Партнёры</h2>
                    <swiper
                        :slides-per-view="1"
                        :space-between="20"
                        :navigation="true"
                        :pagination="true"
                        :breakpoints="{
                            680:{
                                slidesPerView:2
                            },
                            970:{
                                slidesPerView:3
                            },
                            1230:{ 
                                slidesPerView:4,
                                }
                            }"
                    >
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project1.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project2.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project5.jpg" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project12.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project3.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project4.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project6.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project7.jpg" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project8.jpg" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project9.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project10.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project11.png" alt="" class="partners__img">
                            </div>
                        </swiper-slide>

                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project13.jpg" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="partners__item">
                                <img src="img/project14.jpg" alt="" class="partners__img">
                            </div>
                        </swiper-slide>
                    </swiper>
            </div>
        </section>
        <section class="call" id="call">
            <div class="container call__container">
                <h2 class="title call__header">Оставьте заявку</h2>
                <div v-if="loading" class="loading"></div>
                <form v-else action="" class="call__form">
                    <div class="call__form-cont">
                        <div class="error-form error-name">Введите имя</div>
                        <input required v-model="name" placeholder="Имя" type="text" class="call__input call__name">
                    </div>
                    <div class="call__form-cont">
                        <div class="error-form error-tel">Номер телефона должен состоять из 11 цифр</div>
                        <input required v-model="tel" placeholder="Телефон" type="tel" class="call__input call__tel">
                    </div>
                    <textarea v-model="comment" placeholder="Комментарий" name="" id="" cols="30" rows="5" class="call__input call__com"></textarea>
                    <button :disabled="loading" v-on:click="sendMessage" type="submit" class="call__btn">Отправить</button>
                </form>
            </div>
        </section>
    </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import axios from 'axios'
import config from '../components/config'

SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  mounted() {
    let menu = document.querySelector('.menu')
    menu.classList.add('hided')
    window.scrollTo(0,0)
  },
  data() {
    return {
        name: '',
        tel: '',
        comment: '',
        result: null,
        loading: false
    }
  },

  methods: {
    sendMessage: async function(event) {
        event.preventDefault();
        let buttonTel = document.querySelector('.error-tel')
        let buttonName = document.querySelector('.error-name')
        buttonTel.classList.remove('error-active')
        buttonName.classList.remove('error-active')
        if (this.name === '' || this.tel.length !== 11) {
            if (this.name === '') {
                buttonName.classList.add('error-active')
            }
            if (this.tel.length !== 11) {
                buttonTel.classList.add('error-active')
            }
        } else {
            this.loading = true
            this.result = await axios.post(`https://api.telegram.org/${config.telegram.token}/sendMessage?chat_id=${config.telegram.chatId}&parse_mode=html&text=Новый запрос с сайта от: ${this.name}%0AНомер телефона: ${this.tel}%0AКомментарий: ${this.comment}`)
            this.name = ''
            this.tel = ''
            this.comment = ''
            this.loading = false
            if (this.result.status >= 200 && this.result.status < 300) {
                let header = document.querySelector('.call__header')
                header.textContent = 'Заявка успешно отправлена!'
                header.classList.add('success')
            } else {
                let header = document.querySelector('.call__header')
                header.textContent = 'Произошла ошибка, администратор уже разбирается :('
                header.classList.add('not-success')
            }
        }

    }
  }
}
</script>