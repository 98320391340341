<template>
    <div>
        <section class="elements">
            <div class="container elements__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Опорные конструкции</div>
                    </li>
                </ul>
                <h2 class="elements__header">Опорные конструкции</h2>
                <div class="elements__desc-container">
                    <p class="elements__desc-text">
                        К опорным конструкциям относятся различные перфопрофили: кронштейны, стойки, профили. Перфопрофили представляют собой металлическую конструкцию с нанесенной по всей длине перфорацией. В нашем ассортименте имеются профили различного конструктивного исполнения: K серия, U-образный профиль, Z-образный профиль. Выполнены опорные конструкции из цельной листовой низкоуглеродистой стали большой толщины с регулярной перфорацией по одной из сторон или по всем сторонам профиля. 
                    </p>
                </div>
                <h3 class="seria__products-header">Опорные конструкции</h3>
                <ul class="seria__products-list elements__product-list">
                    <li class="seria__products-item elements__product-item">
                        <img src="/img/typeK.png" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Тип K</h4>
                            <router-link class="seria__products-link" :to="{name: 'constructions-element', params: {name: 'K'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item elements__product-item">
                        <img src="/img/type1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Тип U</h4>
                            <router-link class="seria__products-link" :to="{name: 'constructions-element', params: {name: 'U'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item elements__product-item">
                        <img src="/img/op3_1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Тип Z</h4>
                            <router-link class="seria__products-link" :to="{name: 'constructions-element', params: {name: 'Z'}}">Подробнее</router-link>
                        </div>
                    </li>
                </ul>
                <!-- <h2 class="elements__type-header">U-опорные конструкции</h2>
                <p class="elements__type-desc">Опорные конструкции с U-образными стойками по своим характеристикам позволяют заменить изделия, выпускаемые заводами Главэлектромонтаж <span class="normalfont">(</span>ГЭМ<span class="normalfont">)</span>.</p>
                <img src="/img/type1.jpg" alt="" class="elements__type-img">
                <h3 class="elements__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-003-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Нержавеющая сталь</div>
                                        <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Минимальная длина стоек</td>
                            <td class="seria__table-cell">400 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Максимальная длина стоек</td>
                            <td class="seria__table-cell">2500 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Рабочая длина кронштейнов</td>
                            <td class="seria__table-cell">100…500 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла стоек</td>
                            <td class="seria__table-cell">2,0 мм, 2,5 мм, 3,0 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла кронштейнов</td>
                            <td class="seria__table-cell">1,5 мм, 2,0 мм, 2,5 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>
                <h3 class="elements__products-header">Варианты исполнения опорных конструкций с U-образным стойками</h3>
                <ul class="elements__products-list">
                    <li class="elements__products-item">
                        <img src="/img/op1_1.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/op1_2.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/op1_3.jpg" alt="" class="elements__products-img">
                    </li>
                </ul>

                <h2 class="elements__type-header">Опорные конструкции из С-образного страт-профиля</h2>
                <img src="/img/type2.jpg" alt="" class="elements__type-img">
                <h3 class="elements__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-003-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Нержавеющая сталь</div>
                                        <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Минимальная длина стоек</td>
                            <td class="seria__table-cell">400 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Максимальная длина стоек</td>
                            <td class="seria__table-cell">2500 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Рабочая длина кронштейнов</td>
                            <td class="seria__table-cell">200…600 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла стоек</td>
                            <td class="seria__table-cell">2,0 мм, 2,5 мм, 3,0 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла кронштейнов</td>
                            <td class="seria__table-cell">1,5 мм, 2,0 мм, 2,5 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>
                <h3 class="elements__products-header">Варианты исполнения опорных конструкций из С-образного страт-профиля</h3>
                <ul class="elements__products-list">
                    <li class="elements__products-item">
                        <img src="/img/op2_1.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/op2_2.jpg" alt="" class="elements__products-img">
                    </li>
                </ul>

                <h2 class="elements__type-header">Z-опорные конструкции</h2>
                <p class="elements__type-desc">Опорные конструкции с Z-образными стойками рассчитаны на высокие нагрузки. На одну стойку возможен как односторонний, так и двухсторонний монтаж кабельных кронштейнов.</p>
                <h3 class="elements__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-002-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Минимальная длина стоек</td>
                            <td class="seria__table-cell">500 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Максимальная длина стоек</td>
                            <td class="seria__table-cell">2500 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Рабочая длина кронштейнов</td>
                            <td class="seria__table-cell">275…675 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла стоек</td>
                            <td class="seria__table-cell">2,0 мм, 2,5 мм, 3,0 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла кронштейнов</td>
                            <td class="seria__table-cell">2,0 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>
                <h3 class="elements__products-header">Варианты исполнения опорных конструкций с U-образным стойками</h3>
                <ul class="elements__products-list">
                    <li class="elements__products-item">
                        <img src="/img/op3_1.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/op3_2.jpg" alt="" class="elements__products-img">
                    </li>
                </ul> -->
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>