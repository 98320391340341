<template>
    <div>
        <section class="elements">
            <div class="container elements__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Аксессуары монтажные</div>
                    </li>
                </ul>
                <h2 class="elements__header">Аксессуары монтажные</h2>
                <div class="elements__desc-container">
                    <p class="elements__desc-text">
                        Включают в себя изделия для крепления и сборки кабеленесущих систем и маркировки кабельных линий. В их число входят:
                    </p>
                    <ul class="elements__desc-list">
                        <li class="elements__desc-item">
                            <div class="elements__desc-header">Скоба зажимная для крепления одного кабеля</div>
                            <ul class="elements__desc-img-list">
                                <li class="elements__desc-img-item">
                                    <img src="/img/des1_1.jpg" alt="" class="elements__desc-img">
                                </li>
                                <li class="elements__desc-img-item">
                                    <img src="/img/des1_2.jpg" alt="" class="elements__desc-img">
                                </li>
                            </ul>
                        </li>
                        <li class="elements__desc-item">
                            <div class="elements__desc-header">Скоба зажимная для крепления трех кабелей</div>
                            <ul class="elements__desc-img-list">
                                <li class="elements__desc-img-item">
                                    <img src="/img/des2_1.jpg" alt="" class="elements__desc-img">
                                </li>
                                <li class="elements__desc-img-item">
                                    <img src="/img/des2_2.jpg" alt="" class="elements__desc-img">
                                </li>
                            </ul>
                        </li>
                        <li class="elements__desc-item">
                            <div class="elements__desc-header">Зажим кабельный для лотка</div>
                            <ul class="elements__desc-img-list">
                                <li class="elements__desc-img-item">
                                    <img src="/img/des3_1.jpg" alt="" class="elements__desc-img">
                                </li>
                            </ul>
                        </li>
                        <li class="elements__desc-item">
                            <div class="elements__desc-header">Стяжки кабельные нейлоновые, из нержавеющий стали, из нержавеющей стали с полимерным покрытием</div>
                            <ul class="elements__desc-img-list">
                                <li class="elements__desc-img-item">
                                    <img src="/img/des4_1.jpg" alt="" class="elements__desc-img">
                                </li>
                                <li class="elements__desc-img-item">
                                    <img src="/img/des4_3.jpg" alt="" class="elements__desc-img">
                                </li>
                                <li class="elements__desc-img-item">
                                    <img src="/img/des4_2.jpg" alt="" class="elements__desc-img">
                                </li>
                            </ul>
                        </li>
                        <li class="elements__desc-item elements__desc-header">Бирки маркировочные</li>
                        <li class="elements__desc-item elements__desc-header">Крепеж</li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>