<template>
    <div>
        <section class="projects">
            <div class="projects__container container">
                <h2 class="projects__header">Проекты</h2>
                <ul class="projects__list">
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ПАО "Газпромнефть"</h3>
                            <img src="img/projects1.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-development'}}" class="projects__project-link">ООО «Газпромнефть-Развитие»</router-link>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'messoyakhaneftegaz'}}"  class="projects__project-link">АО «Мессояханефтегаз»</router-link>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-vostok'}}" class="projects__project-link">ООО «Газпромнефть-Восток»</router-link>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-yamal-2017'}}" class="projects__project-link">ООО «Газпромнефть-Ямал»</router-link>
                                    <div class="projects__project-desc">Новопортовское месторождение, Кустовая насосная станция</div>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-yamal-2018'}}" class="projects__project-link">ООО «Газпромнефть-Ямал»</router-link>
                                    <div class="projects__project-desc">Новопортовское месторождение, Установка подготовки нефти технологической площадки №3</div>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-yamal-2019'}}" class="projects__project-link">ООО «Газпромнефть-Ямал»</router-link>
                                    <div class="projects__project-desc">Объекты Новопортовского месторождения</div>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'gazpromneft-khantos'}}" class="projects__project-link">ООО «Газпромнефть-Хантос»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ПАО АНК "Башнефть"</h3>
                            <img src="img/projects2.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'bashneft-polyus'}}" class="projects__project-link">ООО «Башнефть-Полюс»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ПАО "Лукойл"</h3>
                            <img src="img/projects3.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'lukoil-permnefteorgsintez'}}" class="projects__project-link">ООО «ЛУКОЙЛ-Пермнефтеоргсинтез»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ПАО "Сибур"</h3>
                            <img src="img/projects4.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'zapsibneftekhim'}}" class="projects__project-link">ООО «ЗапСибНефтехим»</router-link>
                                </li>
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'tobolpromstroy'}}" class="projects__project-link">ООО «Тоболпромстрой»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ООО "НГ-Энерго"</h3>
                            <img src="img/projects5.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'ng-energo'}}" class="projects__project-link">ООО «НГ-Энерго»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ООО "ПромЭлектроКомплект"</h3>
                            <img src="img/projects6.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'promelectrokomplekt'}}" class="projects__project-link">ООО «ПромЭлектроКомплект»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ООО "СНГ Альянс"</h3>
                            <img src="img/projects7.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'cis-alliance'}}" class="projects__project-link">ООО «СНГ Альянс»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">АО "ПремьерСтройДизайн"</h3>
                            <img src="img/projects8.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'premierstroydesign'}}" class="projects__project-link">АО «ПремьерСтройДизайн»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ООО "Юргранд"</h3>
                            <img src="img/projects9.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'jugrand'}}" class="projects__project-link">ООО «Югранд»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="projects__item">
                        <div href="" class="projects__item-link projects__item-front">
                            <h3 class="projects__item-header">ООО "Компания Торум"</h3>
                            <img src="img/projects10.png" alt="" class="projects__item-img">
                        </div>
                        <div class="projects__item-link projects__item-back">
                            <ul class="projects__item-list">
                                <li class="projects__item-item">
                                    <router-link :to="{name: 'project', params: {name: 'torum-company'}}" class="projects__project-link">ООО «Компания Торум»</router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        let menu = document.querySelector('.menu')
        menu.classList.add('hided')
        window.scrollTo(0,0 )
    },
        methods: {
            turn() {
                if (event.currentTarget.classList.contains("projects__active-card"))
                    event.currentTarget.classList.remove("projects__active-card")
                else
                    event.currentTarget.classList.add("projects__active-card")
            }
        }
}
</script>