<template>
    <div>
        <section class="services">
            <div class="container services__container">
                <h2 class="title services__header">Наши услуги</h2>
                <ul class="services__list">
                    <li @click="turn()" class="services__item">
                        <div class="services__item-card services__item-front services__item-front1">
                            <h3 class="services__item-header">Лазерная резка</h3>
                            <img src="img/1.jpg" alt="" class="services__img">
                        </div>
                        <div class="services__item-card services__item-back">
                            <h3 class="services__item-header services__item-header-back">Лазерная резка</h3>
                            <ul class="services__back-list">
                                <li class="services__back-item">Модель – G-Weike LF3015LN;</li>
                                <li class="services__back-item">Мощность – 1500 Вт;</li>
                                <li class="services__back-item">Рабочее поле – 1500х3000 мм;</li>
                                <li class="services__back-item">Толщина разрезаемого металла – до 14 мм;</li>
                                <li class="services__back-item">Газы используемые при резке – азот, кислород, воздух;</li>
                                <li class="services__back-item">Точность позиционирования – ±0,03 мм;</li>
                                <li class="services__back-item">Повторяемость – <span style="font-family: sans-serif;">±</span>0,02 мм;</li>
                                <li class="services__back-item">Предельные отклонения получаемых деталей – h12…h14, H12…H14.</li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="services__item">
                        <div class="services__item-card services__item-front services__item-front2">
                            <h3 class="services__item-header">Гибка листового металла</h3>
                            <img src="img/2.jpg" alt="" class="services__img">
                        </div>
                        <div class="services__item-card services__item-back">
                            <h3 class="services__item-header services__item-header-back">Гибка листового металла</h3>
                            <ul class="services__back-list">
                                <li class="services__back-item">Модели – Durma AD-R 30135 и AD-R 25100;</li>
                                <li class="services__back-item">Максимальная длина гиба – 3100 мм;</li>
                                <li class="services__back-item">Максимальное усилие – 135 тонн;</li>
                                <li class="services__back-item">Стоимость зависит от радиуса, полок, углов, толщины. Для ее уточнения напишите на почту или позвоните.</li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="services__item">
                        <div class="services__item-card services__item-front services__item-front3">
                            <h3 class="services__item-header">Координатно-пробивные работы</h3>
                            <img src="img/3.jpg" alt="" class="services__img">
                        </div>
                        <div class="services__item-card services__item-back">
                            <h3 class="services__item-header services__item-header-back">Координатно-пробивные работы</h3>
                            <ul class="services__back-list">
                                <li class="services__back-item">Модель – Amada Europe 2510;</li>
                                <li class="services__back-item">Развиваемое усилие – 20 тонн;</li>
                                <li class="services__back-item">Максимальные размеры обрабатываемого листа – 1250х5000 мм;</li>
                                <li class="services__back-item">Количество инструмента в барабане – до 27;</li>
                                <li class="services__back-item">Толщина пробиваемого металла – до 3 мм;</li>
                                <li class="services__back-item">Точность позиционирования – <span style="font-family: sans-serif;">±</span>0,1 мм;</li>
                                <li class="services__back-item">Повторяемость – <span style="font-family: sans-serif;">±</span>0,07 мм.</li>
                            </ul>
                        </div>
                    </li>
                    <li @click="turn()" class="services__item">
                        <div class="services__item-card services__item-front services__item-front4">
                            <h3 class="services__item-header">Рубка металла гильотиной</h3>
                            <img src="img/4.jpg" alt="" class="services__img">
                        </div>
                        <div class="services__item-card services__item-back">
                            <ul class="services__back-list">
                                <h3 class="services__item-header services__item-header-back">Рубка металла гильотиной</h3>
                                <li class="services__back-item">Модель – HESSE DHGM 3006, Durma MS 1303, MS 2504;</li>
                                <li class="services__back-item">Толщина разрезаемого металла – до 6 мм;</li>
                                <li class="services__back-item">Длина разрезаемого металла – до 3100 мм.</li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
        <section class="calculator">
            <div class="container calculator__container">
                <h2 class="title calculator__header">Рассчитать стоимость лазерной резки</h2>
                <iframe id="frame" class="frame" src="https://versus.absolute.msk.ru/form/" frameborder="0"></iframe>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        mounted() {
            let menu = document.querySelector('.menu')
            menu.classList.add('hided')
            window.scrollTo(0,0)
        },
        methods: {
            turn() {
                if (event.currentTarget.classList.contains("services__active-card"))
                    event.currentTarget.classList.remove("services__active-card")
                else
                    event.currentTarget.classList.add("services__active-card")
            }
        }
    }
</script>