<template> 
    <div>
        <section class="seria">
            <div class="container seria__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Серия <span class="normalfont">"</span>Макс<span class="normalfont">"</span></div>
                    </li>
                </ul>
                <h2 class="seria__header">Серия "Макс"</h2>
                <div class="seria__desc-container">
                    <p class="seria__desc-text"><strong>Серия «Макс»</strong> представляет собой систему кабельных лотков, способных выдерживать высокие нагрузки, и предназначена для использования на крупных промышленных объектах. Выпускается в виде лотка лестничного типа.</p>
                    <p class="seria__desc-text">Лотки этой серии отличаются повышенной несущей способностью, стойкостью к ветровым и снеговым нагрузкам.</p>
                </div>
                <h3 class="seria__products-header">Продукция</h3>
                <ul class="seria__products-list">
                    <li class="seria__products-item">
                        <img src="/img/maks1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Лестничный лоток</h4>
                            <router-link class="seria__products-link" :to="{name: 'product-maks', params: {name: 'maks-lest'}}">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="seria__products-item">
                        <img src="/img/ac3_1.jpg" alt="" class="seria__products-img">
                        <div class="seria__item-container">
                            <h4 class="seria__products-name">Аксессуары</h4>
                            <router-link class="seria__products-link" :to="{name: 'seria-accessories', params: {seria: 'maks'}}">Подробнее</router-link>
                        </div>
                    </li>
                </ul>

                <h3 class="seria__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-005-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Нержавеющая сталь</div>
                                        <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Длина лотков лестничного типа</td>
                            <td class="seria__table-cell">3.0 м, 6.0 м</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла</td>
                            <td class="seria__table-cell">2.0 мм</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>

                <h3 class="seria__good-header">Конструктивные особенности</h3>
                <ul class="seria__good-list">
                    <li class="seria__good-item">
                        Повышенная прочность
                    </li>
                    <li class="seria__good-item">
                        Размеры и расположение перфорации позволяют установить соединитель в любом месте реза лотка без необходимости сверления дополнительных отверстий
                    </li>
                    <li class="seria__good-item">
                        Увеличенные до 6 метров пролеты между опорными конструкциями
                    </li>
                    <li class="seria__good-item">
                        Лотки имеют загнутую кромку для предотвращения повреждений персонала и кабелей, а также повышения прочности.
                    </li>
                </ul>
                <img src="/img/good3_1.jpg" alt="" class="seria__good-img">
                <h3 class="seria__accses-header">Монтаж серии лотков</h3>
                <p class="seria__accses-text">Монтаж серии лотков Макс производится с использованием различных секций и аксессуаров, таких как:</p>
                <ul class="seria__accses-list">
                    <li class="seria__acces-item">Углы</li>
                    <li class="seria__acces-item">Ответвители</li>
                    <li class="seria__acces-item">Крышки</li>
                    <li class="seria__acces-item">Соединители и др.</li>
                </ul>
                <ul class="seria__accses-img-list">
                    <li class="seria__accses-img-item">
                        <img src="/img/ac3_1.jpg" alt="" class="seria__accses-img">
                    </li>
                    <li class="seria__accses-img-item">
                        <img src="/img/ac3_2.jpg" alt="" class="seria__accses-img">
                    </li>
                    <li class="seria__accses-img-item">
                        <img src="/img/ac3_3.jpg" alt="" class="seria__accses-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>