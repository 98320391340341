<template>
    <div>
        <section class="products">
            <div class="container products__container">
                <h2 class="products__header">Продукция</h2>
                <ul class="products__list">
                    <li class="products__item">
                        <img src="/img/stand.png" alt="" class="products__img">
                        <div class="products__item-container">
                            <h3 class="products__item-header">Серия "Стандарт"</h3>
                            <router-link :to="{name: 'standard'}" class="products__item-link">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="products__item">
                        <img src="/img/opt.png" alt="" class="products__img">
                        <div class="products__item-container">
                            <h3 class="products__item-header">Серия "Оптима"</h3>
                            <router-link :to="{name: 'optima'}" class="products__item-link">Подробнее</router-link>
                        </div>
                    </li>
                    <li class="products__item">
                        <img src="/img/max.png" alt="" class="products__img">
                        <div class="products__item-container">
                            <h3 class="products__item-header">Серия "Макс"</h3>
                            <router-link :to="{name: 'maks'}" class="products__item-link">Подробнее</router-link>
                        </div>
                    </li>
                </ul>
                <swiper class="products__swiper-container"
                        :slides-per-view="1"
                        :space-between="20"
                        :navigation="true"
                        :centeredSlides="true"
                        :pagination="true"
                        :breakpoints="{
                            540:{
                                navigation:true,
                                pagination: false,
                                
                            }}"
                    >
                        <swiper-slide>
                                <div class="products__item">
                                    <img src="/img/stand.png" alt="" class="products__img">
                                    <div class="products__item-container">
                                        <h3 class="products__item-header">Серия "Стандарт"</h3>
                                        <router-link :to="{name: 'standard'}" class="products__item-link">Подробнее</router-link>
                                    </div>
                                </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="products__item">
                                <img src="/img/opt.png" alt="" class="products__img">
                                <div class="products__item-container">
                                    <h3 class="products__item-header">Серия "Оптима"</h3>
                                    <router-link :to="{name: 'optima'}" class="products__item-link">Подробнее</router-link>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="products__item">
                                <img src="/img/max.png" alt="" class="products__img">
                                <div class="products__item-container">
                                    <h3 class="products__item-header">Серия "Макс"</h3>
                                    <router-link :to="{name: 'maks'}" class="products__item-link">Подробнее</router-link>
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                <ul class="products__dop-list">
                    <li class="products__dop-item">
                        <h3 class="products__dop-item-header">Универсальные элементы лотков</h3>
                        <router-link :to="{name: 'elements'}" class="products__dop-item-link">Подробнее</router-link>
                    </li>
                    <li class="products__dop-item">
                        <h3 class="products__dop-item-header">Опорные конструкции</h3>
                        <router-link :to="{name: 'constructions'}" class="products__dop-item-link">Подробнее</router-link>
                    </li>
                    <li class="products__dop-item">
                        <h3 class="products__dop-item-header">Профили и стойки монтажные</h3>
                        <router-link :to="{name: 'racks'}" class="products__dop-item-link">Подробнее</router-link>
                    </li>
                    <li class="products__dop-item">
                        <h3 class="products__dop-item-header">Аксессуары монтажные</h3>
                        <router-link :to="{name: 'accessories'}" class="products__dop-item-link">Подробнее</router-link>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    mounted() {
        let menu = document.querySelector('.menu')
        menu.classList.add('hided')
        window.scrollTo(0,0)
    }
}
</script>
