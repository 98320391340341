<template>
    <div>
        <section class="info">
            <div class="info__container container">
                <h2 class="info__header">Проектировщику</h2>
                <ul class="info__menu">
                    <li class="info__menu-item">
                        <router-link :to="{name: 'news'}" class="info__menu-link">Новинки</router-link>
                    </li>
                    <li class="info__menu-item">
                        <router-link :to="{name: 'climatic'}" class="info__menu-link">Климатическое исполнение</router-link>
                    </li>
                    <li class="info__menu-item">
                        <router-link :to="{name: 'track-load'}" class="info__menu-link">Расчёт нагрузки на трассу</router-link>
                    </li>
                    <li class="info__menu-item">
                        <router-link :to="{name: 'protective-covering'}" class="info__menu-link">Выбор защитного покрытия кабельной трассы</router-link>
                    </li>
                </ul>
                <p class="info__text">
                    Компания «Версус» производит широчайший выбор кабеленесущих систем. Наши решения отвечают самым современным требованиям мировых стандартов, номенклатура производимых нами изделий огромна и чтобы Вам было проще разобраться, наши специалисты готовы оказать полное сопровождение проектов.
                </p>
                <h3 class="info__text-header">Что Вам необходимо, чтобы реализовать проект?</h3>
                <ul class="info__realize-list">
                    <li class="info__realize-item"><b>Во-первых</b> – технические аспекты, расчет объема кабеля, выбор типоразмера лотков, нагрузочные характеристики, тип покрытия от которого будет зависеть срок эксплуатации.</li>
                    <li class="info__realize-item"><b>Во-вторых</b> – выбор решения и точный расчет.</li>
                </ul>
                <p class="info__text">
                    Наши специалисты помогут Вам не только сократить время на расчеты и подбор изделий, но и, опираясь на опыт, знание технологии и особенности монтажа, рассчитают индивидуальное решение под Ваш проект.
                </p>
                <ul class="info__list">
                    <li class="info__item">
                        <img src="img/info1.svg" alt="" class="info__item-img">
                        <div class="info__item-content">
                            <h3 class="info__item-header">Консультация</h3>
                            <p class="info__item-text">Специалисты компании расскажут Вам обо всех преимуществах того или иного лотка, необходимую информацию для реализации Вашей задачи, об особенностях эксплуатации кабельных трасс.</p>
                        </div>
                    </li>
                    <li class="info__item">
                        <img src="img/info2.svg" alt="" class="info__item-img">
                        <div class="info__item-content">
                            <h3 class="info__item-header">Опыт и знания</h3>
                            <p class="info__item-text">Наши менеджеры, инженеры проектного отдела и конструкторы знают все особенности кабеленесущих систем.</p>
                        </div>
                    </li>
                    <li class="info__item">
                        <img src="img/info3.svg" alt="" class="info__item-img">
                        <div class="info__item-content">
                            <h3 class="info__item-header">Справочные материалы</h3>
                            <p class="info__item-text">По Вашему запросу мы предоставим все необходимые
расчеты и документы.</p>
                        </div>
                    </li>
                    <li class="info__item">
                        <img src="img/info4.svg" alt="" class="info__item-img">
                        <div class="info__item-content">
                            <h3 class="info__item-header">Цены и сроки</h3>
                            <p class="info__item-text">Мы подберем для Вас оптимальное решение по цене и срокам изготовления кабеленесущих систем.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    mounted() {
        let menu = document.querySelector('.menu')
        menu.classList.add('hided')
        window.scrollTo(0,0 )
    }
}
</script>