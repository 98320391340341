<template>
    <div>
        <section class="new">
            <div class="new__container container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'info'}" class="product__links-link">Проектировщику</router-link>
                    </li>
                    <li class="product__item-links">
                        <router-link :to="{name: 'news'}" class="product__links-link">Новинки</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">{{ newData.name }}</div>
                    </li>
                </ul>
                <h2 class="new__header">{{ newData.name }}</h2>
                <ul class="new__list">
                    <li v-for="block in newData.blocks" class="new__block">
                        <p class="new__block-text">{{ block.text }}</p>
                        <img :src="block.image" alt="" class="new__block-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import newsData from '../components/news.js'

export default {
    name: 'New',
    data() {
        return {
            newData: newsData.find(n => n.link === this.$route.params.new)
        }
    }
}
</script>