export default [
    {
        link: 'mounting-plate',
        name: 'Монтажная пластина',
        blocks: [
            {
                text: "Для крепления клеммных коробок к лестничным лоткам мы разработали специальную монтажную пластину которая полностью совместима с нашими лестничными лотками. Ниже пример монтажа на лоток серии «Оптима» (используются метизы М6).",
                image: '/img/mp1.png'
            },
            {
                text: "",
                image: '/img/mp2.png'
            },
            {
                text: "Ниже пример монтажа на лоток серии «Макс» (используются метизы М10). Также возможна установка нескольких пластин для установки нескольких коробок или большой коробки.",
                image: '/img/mp3.png'
            }
        ]
    },
    {
        link: 'single-rack',
        name: 'Одиночная стойка',
        blocks: [
            {
                text: "Одиночная стойка для крепления какого-либо оборудования. Возможно непосредственное крепление на стойку, установка С-образных поперечин (болтовое, на нужном расстоянии) и крепление оборудования к ним, либо крепление оборудования на монтажные пластины.",
                image: '/img/sr1.png'
            }
        ]
    },
    {
        link: 'equipment-rack',
        name: 'Стойка аппаратная',
        blocks: [
            {
                text: "Полноразмерные аппаратные стойки. Данные стойки имеют стандартную высоту два метра и типовой ряд по ширине (300, 500, 700, 900 мм). В заказе необходимо указать только ширину и количество широких и узких поперечин в зависимости от типа и количества планируемого к установке оборудования (необходимое количество метизов будет включено). Такая конструкция в любой момент позволяет добавить поперечин (даже просто просверленный по краям и покрашенный уголок или швеллер) для крепления дополнительного оборудования.",
                image: '/img/er1.jpg'
            },
            {
                text: "Перфорация на боковых направляющих и поперечинах позволяет закрепить оборудование различных размеров, а также лотки для крепления кабелей. Также возможен заказ дополнительных опций, например козырьков требуемой ширины и вылета.",
                image: '/img/er2.jpg'
            },
            {
                text: "Стойка позволяет реализовать двухстороннее крепление оборудования. Возможно использование монтажных пластин для крепления оборудования. Возможна установка шины системы дополнительного уравнивания потенциалов для подключения оборудования, металлорукавов, металлических кабельных вводов.",
                image: '/img/er3.jpg'
            },
            {
                text: "Преимущественный подвод кабелей – верхний, непосредственно с эстакады в лотки закрепленные на стойке, которые защищают кабели до высоты 2 метра.",
                image: ''
            }
        ]
    },
]