<template>
    <div>
        <section class="project">
            <div class="project__container container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'projects'}" class="product__links-link">Проекты</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">{{ project.textName }}</div>
                    </li>
                </ul>

                <h2 class="project__header">{{ project.textName }}</h2>

                <p class="project__object project__text"><span class="project__text-head">Объект:</span> {{ project.object }}</p>
                <p class="project__year project__text"><span class="project__text-head">Год поставки:</span> {{ project.year }}</p>
                <p class="project__dogovor project__text"><span class="project__text-head">Предмет договора:</span> {{ project.dogovor }}</p>

                <h3 class="project__img-header">Фотографии</h3>
                <ul class="project__img-list">
                    <li v-for="img in project.images" class="project__img-item">
                        <img class="project__img" :src="img" alt="">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
import projectsData from '../components/projects.js'

export default {
    name: 'Project',
    mounted() {
        let menu = document.querySelector('.menu')
        menu.classList.add('hided')
        window.scrollTo(0,0 )
    },
    data() {
        return {
            project: projectsData.find(p => p.name === this.$route.params.name)
        }
    }
}
</script>