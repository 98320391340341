export default [
    {
        name: 'gazpromneft-development',
        textName: 'ООО «Газпромнефть-Развитие»',
        object: 'Восточно-Мессояхское месторождение, Центральный пункт сбора нефти',
        year: '2015',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p1_1.JPG',
            '/img/p1_2.JPG'
        ]
    },

    {
        name: 'messoyakhaneftegaz',
        textName: 'АО «Мессояханефтегаз»',
        object: 'Восточно-Мессояхское месторождение, Установка предварительного сброса газа',
        year: '2016-2017',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p2_1.JPG',
            '/img/p2_2.JPG'
        ]
    },

    {
        name: 'gazpromneft-vostok',
        textName: 'ООО «Газпромнефть-Восток»',
        object: 'Новосибирская область, Объекты ООО',
        year: '2017',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p3_1.jpg',
            '/img/p3_2.jpg'
        ]
    },

    {
        name: 'gazpromneft-yamal-2017',
        textName: 'ООО «Газпромнефть-Ямал»',
        object: 'Новопортовское месторождение, Кустовая насосная станция',
        year: '2017',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p4_1.JPG',
            '/img/p4_2.JPG'
        ]
    },

    {
        name: 'gazpromneft-yamal-2018',
        textName: 'ООО «Газпромнефть-Ямал»',
        object: 'Новопортовское месторождение, Установка подготовки нефти технологической площадки №3',
        year: '2018',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p5_1.JPG',
            '/img/p5_2.JPG'
        ]
    },

    {
        name: 'gazpromneft-yamal-2019',
        textName: 'ООО «Газпромнефть-Ямал»',
        object: 'Новопортовского месторождения',
        year: '2019',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p6_1.JPG',
            '/img/p6_2.JPG'
        ]
    },

    {
        name: 'gazpromneft-khantos',
        textName: 'ООО «Газпромнефть-Хантос»',
        object: 'Нижневартовский район, Объекты ООО «Газпромнефть-Хантос»',
        year: '2018',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p7_1.JPG',
            '/img/p7_2.JPG'
        ]
    },

    {
        name: 'bashneft-polyus',
        textName: 'ООО «Башнефть-Полюс»',
        object: 'Ненецкий автономный округ, Объекты месторождений Требса и Титова',
        year: '2018',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p8_1.JPG',
            '/img/p8_2.JPG',
            '/img/p8_3.JPG',
            '/img/p8_4.JPG',
            '/img/p8_5.JPG'
        ]
    },

    {
        name: 'lukoil-permnefteorgsintez',
        textName: 'ООО «ЛУКОЙЛ-Пермнефтеоргсинтез»',
        object: 'Технологические установки ООО «ЛУКОЙЛ-Пермнефтеоргсинтез»',
        year: '2019',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p9_1.JPG',
            '/img/p9_2.JPG',
            '/img/p9_3.JPG'
        ]
    },

    {
        name: 'zapsibneftekhim',
        textName: 'ООО «ЗапСибНефтехим»',
        object: 'г. Тобольск, Объекты строительства ООО «ЗапСибНефтехим»',
        year: '2017',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p10_1.jpg',
            '/img/p10_2.jpg',
            '/img/p10_3.jpg'
        ]
    },

    {
        name: 'tobolpromstroy',
        textName: 'ООО «Тоболпромстрой»',
        object: 'г. Тобольск, Объекты строительства ООО «ЗапСибНефтехим',
        year: '2016',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p11_1.JPG',
            '/img/p11_2.JPG',
            '/img/p11_3.JPG'
        ]
    },

    {
        name: 'ng-energo',
        textName: 'ООО «НГ-Энерго»',
        object: 'ПАО «Газпромнефть», Новопортовское месторождение, Газотурбинная электростанция',
        year: '2016',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p12_1.JPG',
            '/img/p12_2.JPG'
        ]
    },

    {
        name: 'promelectrokomplekt',
        textName: 'ООО «ПромЭлектроКомплект»',
        object: 'ПАО «Газпромнефть», Объекты Восточно-Мессояхского месторождения',
        year: '2016',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p13_1.jpg',
            '/img/p13_2.jpg',
            '/img/p13_3.jpg',
            '/img/p13_4.jpg'
        ]
    },

    {
        name: 'cis-alliance',
        textName: 'ООО «СНГ Альянс»',
        object: 'ПАО «Газпромнефть», Новопортовское месторождение, Центральный пункт сбора нефти',
        year: '2016',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p14_1.JPG',
            '/img/p14_2.JPG',
            '/img/p14_3.JPG'
        ]
    },

    {
        name: 'premierstroydesign',
        textName: 'АО «ПремьерСтройДизайн»',
        object: 'ПАО «Газпромнефть», Объекты Восточно-Мессояхского месторождения',
        year: '2015-2017',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p15_1.jpg',
            '/img/p15_2.jpg',
            '/img/p15_3.jpg',
            '/img/p15_4.jpg',
            '/img/p15_5.jpg'
        ]
    },

    {
        name: 'jugrand',
        textName: 'ООО «Югранд»',
        object: 'Салым Петролеум Девелопмент Н.В., Салымская группа месторождений, Установка смешения компонентов АСП',
        year: '2015',
        dogovor: 'Поставка кабельных коробов и лотков, опорных конструкций',
        images: [
            '/img/p16_1.jpg',
            '/img/p16_2.jpg'
        ]
    },

    {
        name: 'torum-company',
        textName: 'ООО «Компания Торум»',
        object: 'ПАО «Новатэк», Объекты Юрхаровского месторождения',
        year: '2014',
        dogovor: 'Поставка опорных конструкций',
        images: [
            '/img/p17_1.JPG',
            '/img/p17_2.JPG',
            '/img/p17_3.JPG',
            '/img/p17_4.JPG',
            '/img/p17_5.JPG',
            '/img/p17_6.JPG'
        ]
    },
]