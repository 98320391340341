<template>
    <div>
        <section class="elements">
            <div class="container elements__container">
                <ul class="product__list-links">
                    <li class="product__item-links">
                        <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                    </li>
                    <li class="product__item-links">
                        <div class="product__links-link">Профили и стойки монтажные</div>
                    </li>
                </ul>
                <h2 class="elements__header">Профили и стойки монтажные</h2>
                <div class="elements__desc-container">
                    <p class="elements__desc-text">
                        Перфорированные монтажные профили, полосы и стойки предназначены для изготовления различных конструкций и размещения оборудования при электромонтажных работах. В их число входят:
                    </p>
                    <ul class="elements__desc-list">
                        <li class="elements__desc-item">Полосы перфорированные <span class="normalfont">(</span>аналог К107<span class="normalfont">)</span></li>
                        <li class="elements__desc-item">Угол перфорированный <span class="normalfont">(</span>аналог К237, К242<span class="normalfont">)</span></li>
                        <li class="elements__desc-item">Профиль Z-образный перфорированный <span class="normalfont">(</span>аналог К241, К238, К239<span class="normalfont">)</span></li>
                        <li class="elements__desc-item">Профиль U-образный перфорированный <span class="normalfont">(</span>аналог К347, К235, К225<span class="normalfont">)</span></li>
                        <li class="elements__desc-item">Профиль С-образный перфорированный <span class="normalfont">(</span>аналог К108, К110<span class="normalfont">)</span></li>
                        <li class="elements__desc-item">Стойка К314, Стойка К305</li>
                        <li class="elements__desc-item">Стойка для светильника К986, Стойка для светильника К987</li>
                    </ul>
                </div>

                <h3 class="elements__tech-header">Технические характеристики</h3>
                <table class="seria__tech-table">
                    <tbody class="seria__table-body">
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">ТУ</td>
                            <td class="seria__table-cell">ТУ-3449-003-90908045-2017</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Материалы и покрытия</td>
                            <td class="seria__table-cell">
                                <ul class="seria__table-list">
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                    </li>
                                    <li class="seria__table-item">
                                        <div class="seria__table-item-header">Порошковая покраска</div>
                                        <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Длина профилей</td>
                            <td class="seria__table-cell">1,0 м, 2,0 м, 2,5 м.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Толщина металла</td>
                            <td class="seria__table-cell">2,0 мм, 2,5 мм, 3,0 мм.</td>
                        </tr>
                        <tr class="seria__table-line">
                            <td class="seria__table-cell">Климатическое исполнение</td>
                            <td class="seria__table-cell">УХЛ1</td>
                        </tr>
                    </tbody>
                </table>

                <h3 class="elements__products-header">Примеры профилей</h3>
                <ul class="elements__products-list">
                    <li class="elements__products-item">
                        <img src="/img/el2_2.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/el2_1.jpg" alt="" class="elements__products-img">
                    </li>
                    <li class="elements__products-item">
                        <img src="/img/el2_3.jpg" alt="" class="elements__products-img">
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>


<script>
export default {
    mounted() {
        window.scrollTo(0,0 )
    }
}
</script>