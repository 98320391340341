<template>
<div>
    <section class="celements">
        <div class="container elements__container">
            <ul class="product__list-links">
                <li class="product__item-links">
                    <router-link :to="{name: 'products'}" class="product__links-link">Продукция</router-link>
                </li>
                <li class="product__item-links">
                    <router-link :to="{name: 'constructions'}" class="product__links-link">Опорные конструкции</router-link>
                </li>
                <li class="product__item-links">
                    <div class="product__links-link">{{ data.name }}-опорные конструкции</div>
                </li>
            </ul>
            <h1 class="elements__type-header">{{ data.name }}-опорные конструкции</h1>
            <p class="elements__type-desc">{{ data.description }}</p>
            <img :src="data.image" alt="" class="elements__type-img">
            <h3 class="elements__tech-header">Технические характеристики</h3>
            <table class="seria__tech-table">
                <tbody class="seria__table-body">
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">ТУ</td>
                        <td class="seria__table-cell">{{ data.table.tu }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Материалы и покрытия</td>
                        <td class="seria__table-cell">
                            <ul class="seria__table-list">
                                <li class="seria__table-item">
                                    <div class="seria__table-item-header">Горячее цинкование методом Сендзимира</div>
                                </li>
                                <li class="seria__table-item">
                                    <div class="seria__table-item-header">Горячее цинкование методом погружения</div>
                                </li>
                                <li class="seria__table-item">
                                    <div class="seria__table-item-header">Порошковая покраска</div>
                                    <div class="seria__table-item-text">По предварительному заказу возможна покраска в соответствии с гаммой цветов RAL.</div>
                                </li>
                                <li v-if="data.table.stal" class="seria__table-item">
                                    <div class="seria__table-item-header">Нержавеющая сталь</div>
                                    <div class="seria__table-item-text">Сталь нержавеющая марки AISI 304L, AISI 316L</div>
                                </li>
                            </ul>
                        </td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Минимальная длина стоек</td>
                        <td class="seria__table-cell">{{ data.table.minLen }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Максимальная длина стоек</td>
                        <td class="seria__table-cell">{{ data.table.maxLen }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Рабочая длина кронштейнов</td>
                        <td class="seria__table-cell">{{ data.table.maxLen }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Толщина металла стоек</td>
                        <td class="seria__table-cell">{{ data.table.thinkhessSt }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Толщина металла кронштейнов</td>
                        <td class="seria__table-cell">{{ data.table.thinkhessKr }}</td>
                    </tr>
                    <tr class="seria__table-line">
                        <td class="seria__table-cell">Климатическое исполнение</td>
                        <td class="seria__table-cell">УХЛ1</td>
                    </tr>
                </tbody>
            </table>
            <h3 class="elements__products-header">Примеры элементов опорных конструкций типа {{ data.name }}</h3>
            <ul class="elements__products-list">
                <li class="elements__products-item" v-for="v in data.variants">
                    <img :src="v" alt="" class="elements__products-img">
                </li>
            </ul>
        </div>
    </section>

</div>
</template>

<script>
import constructions from '@/components/constructions';

export default {
    mounted() {
        window.scrollTo(0,0)
    },
    data() {
        return {
            data: constructions.find(c => c.name === this.$route.params.name)
        }
    }
}
</script>
